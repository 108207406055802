export const CONSTAINT_UTILS = {
  api: {
    frontend_url: 'https://p04xoso.izisoft.io/v1',
    // frontend_url: 'http://103.146.23.186:4004/v1',
    // frontend_url: 'http://localhost:4004/v1',
  },
  menu: {
    recusive: '/v1/menus/recusive',
  },
  ROLE: {
    ID_CONG_TAC_VIEN: '6143888e28cd272928bd0fa2'
  }
};
