export const ROUTER_UTILS = {
  config: {
    base: {
      home: '',
      dashboard: 'dashboard',
      liveOnlyLottery: 'only-lottery',
      liveMultiLottery: 'multi-lottery/central-side',
      liveMultiLotteryOfSouthSide: 'multi-lottery/south-side',
      givePrize: "prize-calendar",
      checkLotteryPicket: "check-lottery",
      printLottery: "print-lottery",
      insertLotteryResults: "insert-lottery",
      news: "news",
      newsContent: "news-content/:id",
      computationLottery: "computation",

      mienBac: 'ket-qua-mien-bac',
      mienTrung: 'ket-qua-mien-trung',
      mienNam: 'ket-qua-mien-nam',

      mien: 'ket-qua-mien',

      kqxo: 'ket-qua-xo-so/:idProvince/:dateStart',
      liveProvinceLottery: 'province-lottery',

      tryPlay655: 'power655',
      tryPlay645: 'mega645',
      tryPlay4d: 'max4d',
      power655Result: 'power655-result',
      mega645Result: 'mega645-result',
      max4dResult: 'max4d-result',
      soDauDuoiMienBac: 'so-dau-duoi-mien-bac',
      soDauDuoiMienTrung: 'so-dau-duoi-mien-trung',
      soDauDuoiMienNam: 'so-dau-duoi-mien-nam',
      frequency: 'frequency',
      basicStatistic: 'basic-statistic',
      lottoStatistic: 'lotto-statistic',
      detailBasicStatistic: 'detail-basic-statistic/:id',

      // 5 page - thống kê
      important: 'thong-ke-loto-quan-trong',
      lotoBoso: 'thong-ke-loto-boso',
      lotoNhanh: 'thong-ke-loto-nhanh',
      lotoGan: 'thong-ke-loto-gan',
      theoDauDuoi: 'thong-ke-loto-dau-duoi',

      // 4 page - thống kê đặc biệt
      specialStatistic: 'special-statistic',
      tong: 'thong-ke-dac-biet-tong',
      chuky: 'thong-ke-dac-biet-chu-ky',
      thang: 'thong-ke-dac-biet-thang',

      // 2 page - thống kê cầu
      cauloto: "thong-ke-cau-loto",
      caubachthu: "thong-ke-cau-bach-thu",

      //giải đáp giấc mơ
      dreamAnswer: 'dream-answer',

    },
    auth: {
      root: 'auth',
      signIn: 'sign-in',
      signUp: 'sign-up',
      forgotPassword: 'forgot-password',
      forgotPasswordEmailSent: 'forgot-password-email-sent',
      passwordReset: 'password-reset',
      passwordResetFailed: 'password-reset-failed',
      passwordResetSucceeded: 'password-reset-succeeded',
    },
    settings: {
      root: 'settings',
      account: 'account',
      appearance: 'appearance',
      billing: 'billing',
      blockedUsers: 'blocked-users',
      notifications: 'notifications',
      security: 'security',
      securityLog: 'security-log',
    },
    user: {
      root: 'users',
      overview: 'overview',
      profile: ':username',
    },
    errorResponse: {
      notFound: '404',
    },
  },
};
